import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';

const backgroundColor = '#d22222';

const fontSize = {
  small: '1rem',
  default: '1rem',
  large: `${18 / 16}rem`,
};

const iconSize = {
  small: `${18 / 16}rem`,
  default: `${18 / 16}rem`,
  large: `${22 / 16}rem`,
};

const iconSpacing = {
  small: '16px',
  default: '22px',
  large: '30px',
};
const height = {
  small: '40px',
  default: '48px',
  large: '68px',
};

const padding = {
  small: '10px 30px',
  default: '10px 45px',
  large: '10px 60px',
};

const IconWrapper = styled.div`
  display: inline-block;
`;

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: ${props => height[props.size]};
  padding: ${props => padding[props.size]};
  font-size: ${props => fontSize[props.size]};
  font-weight: bold;
  color: white;
  background: ${props => props.backgroundColor || backgroundColor};
  ${props => (props.shadow ? 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)' : '')}
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${props => darken(0.04, props.backgroundColor || backgroundColor)};
  }

  ${IconWrapper} {
    font-size: ${props => iconSize[props.size]};
    margin-right: ${props => iconSpacing[props.size]};
  }
`;

function Button({ icon, children, ...rest }) {
  return (
    <Wrapper {...rest}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </Wrapper>
  );
}

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
  backgroundColor: PropTypes.string,
  icon: PropTypes.node,
  shadow: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  size: 'default',
  backgroundColor,
  icon: null,
  shadow: false,
  children: null,
};

export default Button;
export { default as RectangularButton } from './RectangularButton';
export { default as RoundButton } from './RoundButton';
