import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { darken } from 'polished';

import Icon from '../components/Icon';
import { MainLayout } from '../components/Layout';
import SEO from '../components/SEO';
import Container from '../components/Container';
import pronunGif from '../images/pronunciation-checker/pronun-gif.gif';
import jsonData from '../content/pronuciation-checker.json';
import Button from '../components/Button';
import DropDown, { TextItem } from '../components/DropDown';

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 3em;
  color: ${p => p.theme.bgHeader};
  margin-bottom: 1em;
`;

const Description = styled.h5`
  font-style: normal;
  font-weight: 100;
  font-size: 1.5em;
  color: black;
  letter-spacing: 2px;
  margin-bottom: 2em;
`;

const TryWebApp = styled(Button)`
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8em;
  margin-right: 0.8em;
  white-space: nowrap;
`;

const ContentContainer = styled.div`
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;

  padding: 5%;
`;

const ImageContainer = styled.div`
  flex-basis: 50%;
`;

const Downloads = styled(DropDown)`
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 6px;
  padding-left: 4em;
  padding-right: 4em;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.8em;

  &:hover {
    background-color: ${darken(0.1, '#fff')};
  }
`;

const Platform = styled(TextItem)`
  text-align: left;
  text-transform: none;
  text-decoration: none;
`;

const PlatformIcon = styled(Icon)`
  margin-right: 0.8em;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  font-size: 1rem;
  display: flex;
  margin-top: 3em;

  @media screen and (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 920px) {
    flex-flow: column nowrap;

    ${ContentContainer} {
      width: 80vw;
      margin: 0 auto;
      padding: 0;
      text-align: center;
      align-items: center;
    }

    ${ImageContainer} {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    flex-flow: column nowrap;

    ${ImageContainer} {
      width: 100%;

      img {
        margin: 0 auto;
        display: block;
      }
    }

    ${ContentContainer} {
      flex-flow: column nowrap;
      align-items: center;
      text-align: center;

      ${Buttons} {
        flex-flow: column nowrap;
        align-items: stretch;

        ${TryWebApp} {
          margin-bottom: 1em;
          margin-right: 0;
          font-size: 1.2em;
        }

        ${Downloads} {
          width: 100%; // fix its parent width
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    font-size: 0.6em;
  }
`;

const PronunciationChecker = () => (
  <MainLayout>
    <SEO title="Home" keywords={[`dolonline`, `dolenglish`]} />
    <Container bgc="#FCFCFC">
      <Wrapper>
        <ContentContainer>
          <Title>{jsonData.title}</Title>
          <Description>{jsonData.description}</Description>
          <Buttons>
            <TryWebApp onClick={() => navigate(jsonData.webApp.href)}>{jsonData.webApp.text}</TryWebApp>
            <Downloads label={jsonData.downloads.text}>
              {jsonData.downloads.platforms.map(platform => (
                <Platform key={platform.id} as="a" href={platform.href} target="_blank" rel="noreferrer noopener">
                  <PlatformIcon type={platform.iconType} />
                  {platform.text}
                </Platform>
              ))}
            </Downloads>
          </Buttons>
        </ContentContainer>
        <ImageContainer>
          <img src={pronunGif} alt="Pronunciation checker gif" />
        </ImageContainer>
      </Wrapper>
    </Container>
  </MainLayout>
);

export default PronunciationChecker;
