import styled from 'styled-components';

const RectangularButton = styled.span`
  font-size: 0.8rem;
  color: white;
  padding: 10px 20px;
  background: ${p => p.theme.brand};
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${p => p.theme.redButtonHoverColor};
  }
`;

export default RectangularButton;
